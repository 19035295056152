import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueCookies from 'vue-cookies'
Vue.use(Vuex)
Vue.use(VueCookies)
export default new Vuex.Store({
  state: {
    loggedIn: null,
    msg:null,
    user:null,
  },
  mutations: {
    setUser:(state, user) => state.user = user,
    setFavorites:(state, favorites)=>{
      state.user.movieFavorites = favorites.movieFavorite;
      state.user.starFavorites = favorites.starFavorite;
      state.user.tvSeriesFavorites = favorites.tvSeriesFavorite;
    },
    setLogin:(state, loggedIn) => state.loggedIn = loggedIn,
  },
  actions: {
    async setLogin({commit}){

    },
    async loginCheck({commit}){
      await axios.get(`/api/auth/loginCheck`)
      .then(response =>{
        if(response.data.success){
          if( !response.data.user.displayName) response.data.user.displayName = ''
          commit('setUser', response.data.user)
          commit('setLogin', true)
        }
        if(response.data.error){
          commit('setUser', null)
          commit('setLogin', false)
        }
      })
    },
    async editorCheck({commit}){
      await axios.get(`/api/auth/editorCheck`)
      .then(response =>{
        if(response.data.success){
          commit('setUser', response.data.user)
          commit('setLogin', true)
        }
        if(response.data.error){
          commit('setUser', null)
          commit('setLogin', false)
        }
      })
    },
    async adminCheck({commit}){
      await axios.get(`/api/auth/adminCheck`)
      .then(response =>{
        if(response.data.success){
          commit('setUser', response.data.user)
          commit('setLogin', true)
        }
        if(response.data.error){
          commit('setUser', null)
          commit('setLogin', false)
        }
      })
    },
  },
  modules: {
  },
  getters:{
    
  }
})
