<template lang="pug">
  .suggestionWrap
    .suggestionContainer
      .suggestion(v-bind:class="{active:active}")
        //- p 電影導覽
        p 我想要
        select(v-model="movieGenre" )
          option(value='' disabled) 看部電影 ▼
          option(v-for="(gen, index) in genreListEN" v-bind:value="gen" ) {{genreListCH[index]}}
        button(@click="getMovieSuggestion") 請給我推薦
          i.fas.fa-redo-alt.suggestion-redo(v-if="active" v-bind:class="[{spin : this.loading }]")
      .suggestionList(v-bind:class="{active:active}")
        .cardSpinner(v-if="loading")
          Spinner
        .cardWrap(v-else)
          
          .card( v-for="(movie, index) in movieList" :key="index")
            router-link(v-bind:to="`${movie.model}/${movie._id}`")                             
              img(v-bind:src="movie.thumbnail")
              .cardTitle(v-if="movie.nameCH")
                p {{movie.nameCH}}
                p {{movie.nameEN}}
              .cardTitle(v-else)
                p {{movie.nameEN}}
        transition(name="fade" v-on:before-enter="beforeEnter" v-on:before-leave="beforeLeave")
          .more(v-if="movieList.length > 0" )
            router-link(:to="`/genre/movie/${movieGenre}`") 更多{{selectedGenre}}電影

</template>
<script>
import SliderCard from '@/components/SliderCard'
import Spinner from 'vue-simple-spinner'
import mtesCard from '@/components/mtesCard'

export default {
  name: 'Suggestion',
  components:{
    SliderCard,
    Spinner,
    mtesCard
  },
  data(){
    return{
      movieGenre:'',
      movieList:[],
      tvGenre:null,
      tvList:null,
      loading:false,
      active:false,
      selectedGenre:null,
      genreListEN: ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'],
      genreListCH: ['動作','冒險','動畫','喜劇','犯罪','紀錄','劇情','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚','戰爭','西部'],

    }
  },
  watch:{
    movieGenre: {
      handler() {
        sessionStorage.setItem('suggestionMovieGenre', JSON.stringify(this.movieGenre));
      },
      deep: true,
    },
    movieList: {
      handler() {
        sessionStorage.setItem('suggestionMovieList', JSON.stringify(this.movieList));
      },
      deep: true,
    }
  },
  methods:{
    async init(){
      if (sessionStorage.getItem('suggestionMovieList')) this.movieList = JSON.parse(sessionStorage.getItem('suggestionMovieList'));
      if (sessionStorage.getItem('suggestionMovieGenre')){ 
        this.active = true
        this.movieGenre = JSON.parse(sessionStorage.getItem('suggestionMovieGenre'));
        let x = this.genreListEN.indexOf(this.movieGenre)
        this.selectedGenre = this.genreListCH[x]
      }     

    },
    async getMovieSuggestion(){
      this.movieList = [];
      if(this.movieGenre){
        try{
          let x = this.genreListEN.indexOf(this.movieGenre)
          this.selectedGenre = this.genreListCH[x]
          this.movieList = [];
          this.loading = true;
          var payload={type:'movie',genre:this.movieGenre}
          var response = await this.axios.post(`/api/suggestion`, payload);
          this.movieList = response.data;
          this.active = true;
          this.loading = false;
        }catch(err){
          this.flash(err, 'error')
        }
      }else this.flash('Please Select a Genre', 'error')
    },
    beforeEnter(el){
      el.style.transitionDelay = '.7s'
    },
    beforeLeave(el){
      el.style.transitionDelay = '0s'
    }
  },
  created(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
  @keyframes gradient {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
  .more{
    text-align:center;
    margin-top:20px;
  }
  @keyframes fadeIn {
    from {opacity: 0; margin-top:10px;}
    to {opacity: 1; margin-top:0;}
  }
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform:rotate(335deg)}
  }
  
  .suggestionContainer{
    // max-width:1200px;
    // height:100%;
    // margin:0 auto;
    display:flex;
    // flex-direction: column;
  }
  .suggestionWrap{
    width:100%;
    height:400px;
    padding:100px 50px;
    opacity:0;
    background-size: cover;
    overflow:hidden;
    animation: fadeIn .5s;
    animation-delay: .5s;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;
  }
  
  .suggestion{
    width:100%;
    height:100%;
    margin-top:50px;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    transition:width .7s;
    &.active{
      width:50%;
    }
    p{
      font-size:32px;
    }
    select{
      -webkit-appearance:none !important;
      &:focus{
        border:solid 1px grey;
        outline:none;
      }
      margin:5px 20px 0 20px;
      background:transparent;
      // color:white;
      // border:solid 1px white;
      border:solid 1px lightgrey;
      padding:10px;
      transition: border .3s;
      option{
        color:black;
      }
    }
    button{
      &:focus{
        border:solid 1px grey;
        outline:none;
      }
      width:170px;
      // margin-top:-10px;
      border-radius:5px;
      // border:white solid 1px;
      border:solid 1px lightgrey;
      // color:white;
      background:transparent;
      font-size:24px;
      transition: border .3s;
      .suggestion-redo{
        transform: rotate(-25deg);
        transition:transform .2s;
      }
      .suggestion-redo.spin{
        animation:spin .5s;
      }
      &:hover{
        .suggestion-redo{
          transform: rotate(0deg);
        }
      }
      
    }
  }
  .suggestionList{
    width:0;
    transition:width .7s;
    float:left;
    &.active{
      width:50%;
    }
    .cardSpinner{
      width:100%;
      height:100%;
      padding:50px;
      display:flex;
      justify-content:center;
    }
    .cardWrap{
      display:flex;
      width:100%;
      .card{
        width:110px;
        margin:0 10px;
        opacity:0;
        text-align: center;
        animation:fadeIn .5s;
        animation-fill-mode: forwards;
        transition:transform .3s,box-shadow .3s;
        &:hover{
          transform:translateY(-10px);
        }
        &:nth-child(2){
          animation-delay: .2s;
        }
        &:nth-child(3){
          animation-delay: .5s;
        }
        &:nth-child(4){
          animation-delay: .7s;
        }
        &:nth-child(5){
          animation-delay: .9s;
        }
        &:nth-child(6){
          animation-delay: 1.1s;
        }
        &:nth-child(7){
          animation-delay: 1.2s;
        }
        &:nth-child(8){
          animation-delay: 1.3s;
        }
        &:nth-child(9){
          animation-delay: 1.4s;
        }
        &:nth-child(10){
          animation-delay: 1.5s;
        }
        img{
          width:100px;
          height:150px;
          box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.5);
          transition:box-shadow .3s;
          background: linear-gradient(-45deg,#fff2ae,#91c4fb, #ff8292,  #39f5c5 );
          background-size: 400% 400%;
          animation: gradient 3s ease infinite;
        }
        &:hover img{
          box-shadow:rgba(0, 0, 0, 0.3) 0px 6px 12px 0px;
        }

        .cardTitle{
          p{
            margin:5px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p:first-child{
            font-weight: 700;
          }
          p:nth-child(2){
            font-size:12px;
          }

        }
      }
    }
  }
  @media screen and (max-width:1200px) {
    .suggestionWrap{
      height:auto;
    }
    .suggestionContainer{
      flex-direction: column;
    }
    .suggestion, .suggestion.active{
      width:100%;
      margin-top:0;
      margin-bottom:50px;
    }
    .suggestionList{
      // width:100%;
      height:50px;
      transition:height .3s;
      &.active{
        width:100%;
        height:300px;
      }
      .cardWrap{
        justify-content: center;
      }
    }
    .cardSpinner{
      width:100%;
      height:100%;
      padding:50px;
      display:flex;
      justify-content: center;
    }
  }
  @media screen and (max-width:1080px) {
    .suggestionTitle{
      p::after{
        display:none;
      }
      p::before{
        display:none;
      }
    }
  }
  @media screen and (max-width:623px) {
    .suggestionTitle{
      margin-bottom:25px
    }
    .suggestion, .suggestion.active{
      flex-direction: column;
      align-items: center;
      p{
        margin:10px 0;
      }
      select{
        margin:10px 0;
      }
      button{
        margin:10px 0;
      }
    }
  }
  @media screen and (max-width:425px) {
    .suggestionWrap{
      padding:10px;
    }
    .suggestionList.active{
      padding:10px 0;
      overflow-x: auto;
      overflow-y: hidden;
      .cardWrap{
        justify-content: flex-start;
        }
    }
  }

</style>
