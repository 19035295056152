import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router.js'
import store from './store/store.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
// import Vuelidate from 'vuelidate' // get rid of?
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";
import lineClamp from 'vue-line-clamp'
import VueFlashMessage from 'vue-flash-message';
import TextareaAutosize from 'vue-textarea-autosize'
// import Vue2TouchEvents from 'vue2-touch-events'
import VueJsonLD from 'vue-jsonld'
import VueLuxon from "vue-luxon";
import Ads from 'vue-google-adsense'

// import Dayjs from 'vue-dayjs';
// import VueDayjs from 'vue-dayjs-plugin'
// Vue.use(VueDayjs)
Vue.use(TextareaAutosize)
Vue.use(require('vue-script2'))
Vue.use(Ads.AutoAdsense, { adClient: 'pub-5786876920818801' })
// Vue.use(Vue2TouchEvents) 
Vue.use(VueJsonLD)

  //   {
//   disableClick: false,
//   touchClass: '',
//   tapTolerance: 10,
//   touchHoldTolerance: 400,
//   swipeTolerance: 30,
//   longTapTimeInterval: 400,
//   namespace: 'touch'
// }


// const moment = require('moment')
// require('moment/locale/zh-tw')

Vue.use(VueFlashMessage,{
  messageOptions: {
    timeout: 3000,
    // important: true,
    // autoEmit: false,
    pauseOnInteract: true
  }
});

Vue.use(VueMeta,{
  // keyName: 'head',
  // debounceWait:10
})
Vue.use(lineClamp)
// Vue.use(Vuelidate)
Vue.use(VueCookies)
// Vue.use(require('vue-moment'),{moment});
Vue.use(VueLuxon,{
  input: {
    zone: "utc",
    // format: "iso"
  },
  output:{
    locale: 'zh-Hans-TW',
    output: "med"
  }
});
// Vue.use(Dayjs,{

// })
// Vue.use(require('vue-moment'),{moment});
Vue.use(VueAxios, axios, VueCookies)

Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: "G-43N4FNZMRR" }
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
