<template lang="pug">
  .section-left(:class="{active:mobileTab}")
    .left-tag.pointer(@click="toggleTab")
      i.fas.fa-chevron-up(:class="{active:mobileTab}")
    .left-container
      .left-mobile-bg(v-if="mobileTab" @click="closeTab")
      .left-wrap()
        transition(name="slideup")
          .left-section(v-if="loading > 0")
            .left-headline
              //- h4 熱門評論
              //- Recent Reviews
              h4 最新評論
            .left-content
              .left-recent-reviews-wrap
                .left-recent-review(style="display:flex;" v-for="recentReview in recentReviews")
                  router-link( :to="`/movie/${recentReview.movie._id}`") 
                    img.left-recent-review-poster(:src="recentReview.movie.thumbnail")
                  div(style="width:100%;")
                    v-clamp.left-review-bubble(v-if="recentReview.content" autoresize :max-lines="3" style="line-height:24px;") {{recentReview.content}}
                      template( #after="{ toggle, expanded, clamped }")
                        span.pointer(
                          style="margin-top:20px; margin-left:20px;"
                          v-if="expanded || clamped"
                          class="toggle btn btn-sm"
                          @click="toggle") <br> <div style="text-align:right;">{{clamped ? '更多' : '較少'}}</div>
                    .left-review-carot
                    .left-review-user
                      img.left-profile(v-if="recentReview.user.photo" :src="recentReview.user.photo")
                      img.left-profile(v-else src="@/assets/img/awakescore.png")
                      .left-user-info
                        div.left-user-name {{recentReview.user.displayName}}
                        div
                          img.left-user-score(v-for="n in recentReview.score" src="@/assets/img/awakescore1.png")
                    router-link(style="font-weight:700;display:flex;justify-content:flex-end;align-items:center;" :to="`/movie/${recentReview.movie._id}`") 
                      p(v-if="recentReview.movie.nameCH") {{recentReview.movie.nameCH}}
                      p(v-else) {{recentReview.movie.nameEN}}
        transition(name="slideup")
          .left-section(v-if="loading > 1")
            router-link(to="/browse/article/latest")
              .left-headline
                h4 焦點新聞
                p.left-more 查看全部
            .left-content
              .left-articles-wrap
                .left-article(v-for="article in articles")
                  router-link(:to="`/article/${article._id}`")
                    img(:src="article.thumbnail")
                    .left-article-info
                      v-clamp(autoresize :max-lines="2" style="line-height:24px;") {{article.title}}             
        transition(name="slideup")
          .left-section(v-if="loading > 2")
            router-link(to="/browse/movie/inTheaters")
              .left-headline
                h4 現正熱映
                p.left-more 查看全部
            .left-content
              .left-mtes-list(v-for="movie in inTheaters")
                router-link(:to="`/movie/${movie._id}`")
                  .left-mtes-list-score-wrap
                    img.left-mtes-list-poster(:src="movie.thumbnail")
                    .left-mtes-list-score
                      span(v-if="movie.criticScoreAverage") {{(movie.criticScoreAverage / 5)*100}}
                      span(v-else) --
                    .left-mtes-list-score
                      span(v-if="movie.userScoreAverage") {{(movie.userScoreAverage / 5)*100}}
                      span(v-else) --
                  .left-mtes-list-name
                    p(v-if="movie.nameCH") {{movie.nameCH}}
                    p {{movie.nameEN}}

        //- .left-section
          router-link(to="/browse/movie/comingSoon")
            .left-headline
              h4 即將上映
              p 查看全部

</template>

<script>
import VClamp from 'vue-clamp'

export default {
  name: 'LeftBar',
  components: {
    VClamp
  },
  data(){
    return{
      loading:0,
      recentReviews:[],
      comingSoon:[],
      inTheaters:[],
      articles:[],
      mobileTab:false,
    }
  },
  props:{
    incomingArticles:Array,
    incomingComingSoon:Array,
    incomingInTheaters:Array,
  },
  methods:{
    async init(){
      this.loading = 0;
      var recentReviews = await this.axios.get('/api/recentReviews/movie');
      this.recentReviews = recentReviews.data;
      this.loading++
      if(this.incomingArticles){
        this.articles = this.incomingArticles.splice(0,5)
        this.loading++;
      }else{
        var articles = await this.axios.get('/api/articles/latest');
        this.articles = articles.data.splice(0,5);
        this.loading++;
      }
      if(this.incomingInTheaters){
        this.inTheaters = this.incomingInTheaters;
        this.loading++
      }else{
        var inTheaters = await this.axios.get('/api/newMovieReleases/0');
        this.inTheaters = inTheaters.data;
        this.loading++
      }
    },
    async toggleTab(){
      this.mobileTab = !this.mobileTab;
    },
    async closeTab(){
      this.mobileTab = false;
    },
  },
  async created(){
    this.init();
  }
}
</script>
<style lang="scss">
  .left-recent-review{
    display:flex;
    margin-top:20px;
    .left-recent-review-poster{
      width:45px;
      margin-right:10px;
    }
  }
  .left-mtes-list-name{
    width:calc(100% - 124px);
    p:first-child{
      font-weight:700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p:last-child{
      font-size:14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .left-mtes-list{
    a{
      position:relative;
      display:flex;
      margin-bottom:10px;
      align-items:center;
      &:hover::after {
      opacity: 0.3;
      transform: scaleY(1);
      transition: all 150ms ease-out;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(5,5,5,0.1);
        z-index: -1;
        opacity: 0;
        transform-origin: top center;
        transform: scaleY(0);
        transition: all 100ms ease-in;
    }
    }
    
  }
  .left-mtes-list-score-wrap{
    display:flex;
    // justify-content:
    align-items:center;
  }
  .left-mtes-list-poster{
    width:40px;
    margin-right:10px;
  }
  .left-mtes-list-score{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right:5px;
    color: #050505;
    font-weight: bold;
    border-radius: 4px;
    background-color: #e6e6e6;
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
  .left-section{
    margin-bottom:20px;
    &:hover{
      .left-more{
        opacity:1;
        transform:translateX(0px);
      }
    }
  }
  .left-article{
    position:relative;
    margin-bottom:10px;
    padding:5px 0;
    a{
      display:flex;
      align-items:center;
      img{
        width:100px;
        margin-right:10px;
      }
      .left-article-info{
        font-weight:700;
      }
    }
    &:hover::after {
      opacity: 0.3;
      transform: scaleY(1);
      transition: all 150ms ease-out;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(5,5,5,0.1);
        z-index: -1;
        opacity: 0;
        transform-origin: top center;
        transform: scaleY(0);
        transition: all 100ms ease-in;
    }
  }
  .left-wrap{
    width:100%;
    flex-direction: column;
    position: relative;
  }
  .left-headline{
    width:100%;
    display:flex;
    border-bottom:solid 1px black;
    justify-content: space-between;
    align-items: center;
    .left-more{
      font-size:12px;
      opacity:0;
      transform:translateX(7px);
      transition:opacity .2s, transform .2s;
    }
  }
  .left-content{
    margin-top:5px;
  }
  .left-review-bubble{
    padding:5px;
    border:solid 1px lightgrey;
    border-radius:5px;
    position:relative;
    margin:0 0 20px 0;
  }
  .left-user-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .left-review-carot{
    position:relative;
  }
  .left-review-carot:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 10px solid lightgrey;
  border-left: 10px solid transparent;
  border-top: 10px solid lightgrey;
  border-bottom: 10px solid transparent;
  left: 20px;
  bottom: 1px;
}
.left-review-carot:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 8px solid #f5f5f5;
  border-left: 8px solid transparent;
  border-top: 8px solid #f5f5f5;
  border-bottom: 8px solid transparent;
  left: 22px;
  bottom: 5px;
}
.left-user-info{
  display:flex;
  flex-direction: column;
  margin-left:10px;
  width:calc(100% - 45px);
}
.left-user-score{
  width:15px;
  margin-right:4px;
}
.left-review-thumb{
  width:30px;
}
.left-review-user{
  display:flex;
  align-items:center;
  margin-left:45px;
  margin-top:-10px;
  .left-profile{
    width:30px;
    border-radius:5px;
    padding:2px;
    background-color:lightgrey;
  }
}

@media screen and(max-width:1024px) {
  .left-mtes-list-score{
    display:none;
  }
  .left-mtes-list-name{
    width:80%;
  }
  .left-user-info {
    width: 100%;
  }
  .left-review-user{
    margin-left:0;
  }
}
.left-tag{
  display:none;
}
@media screen and (max-width:768px) {
  .section-left{
    width:80%;
    position:fixed;
    top:50px;
    z-index: 9;
    padding:0;
    margin-left:calc(-80%);
    transition:margin .3s;

    &.active{
      margin-left: 0;
    }
  }
  .left-mobile-bg{
    width:100vw;
    height:100vh;
    position:fixed;
    top: 0;
    left:0;
    background:rgba(0,0,0,.5);
    z-index:7;
  }


  .left-wrap{    
    height:calc(100vh - 100px);
    left:0;
    background:#f5f5f5;
    z-index: 9;
    box-shadow:8px 6.4px 38.4px rgb(39 44 49 / 6%), 1px 3.2px 8px rgb(39 44 49 / 3%);
    overflow:auto;    
    padding:5px;
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .left-section{
      width:98%;
    }
  }
  .left-tag{
    display:block;
    width:40px;
    height:40px;
    background:#f5f5f5;
    border-right:solid lightgrey 1px;
    border-top:solid lightgrey 1px;
    border-bottom:solid lightgrey 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position:absolute;
    right:-40px;
    top:100px;
    z-index:9;
    text-align:center;
    padding-top:10px;
    box-shadow:0px 1px 5px 1px rgb(0 0 0 / 10%);
    i{
      transform:rotate(90deg);
      transition:transform .3s;
      &.active{
        transform:rotate(-90deg);
      }
    }
   
  }
  
}
</style>
