<template lang="pug">
.search
  .searchbar(v-bind:class="{active: searchQuery.length > 0}")
    input( type="search" v-model.trim="searchQuery"  @input="search($event);" v-on:keyup.enter="search($event)" )
    .searchIcon(v-bind:class="{active: searchQuery.length > 0}" @click="fullSearch()" @keyup.enter="fullSearch()")
  transition( name="fade")
    .searchbarWrap(v-if="searchQuery.length > 0")
      ul.searchbarResults
        //- .searchClose(v-on:click="closeSearch()")
          svg#close(xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100")
            g
              path(d="M87.8063801,2.09209442 L2.09209442,87.8063801 C-0.697364807,90.5958394 -0.697364807,95.1184464 2.09209442,97.9079056 C4.88155365,100.697365 9.40416064,100.697365 12.1936199,97.9079056 L97.9079056,12.1936199 C100.697365,9.40416064 100.697365,4.88155365 97.9079056,2.09209442 C95.1184464,-0.697364807 90.5958394,-0.697364807 87.8063801,2.09209442 Z")
              path(d="M2.09209442,12.1936199 L87.8063801,97.9079056 C90.5958394,100.697365 95.1184464,100.697365 97.9079056,97.9079056 C100.697365,95.1184464 100.697365,90.5958394 97.9079056,87.8063801 L12.1936199,2.09209442 C9.40416064,-0.697364807 4.88155365,-0.697364807 2.09209442,2.09209442 C-0.697364807,4.88155365 -0.697364807,9.40416064 2.09209442,12.1936199 Z")
        div(v-if="loading")
          Spinner
        div(v-if="!loading && searchResults.length == 0" style="color:white; text-align:center;") 未找到結果
        div(v-if="!loading" v-for="result in searchResults" v-on:click="closeSearch()")
          router-link( v-bind:to="`/${result.model}/${result._id}`")
            li.result
              .searchPoster
                img(v-bind:src="`${result.poster}`")
              span.title(v-if="result.nameEN && result.nameCH")
                span.titleOne(v-html="result.nameCH")
                br
                span.titleTwo(v-html="result.nameEN")
              span.title(v-else)
                span.titleOne(v-html="result.nameEN")
</template>

<script>
import Spinner from 'vue-simple-spinner'

export default {  
  name: 'SearchBar',
  components:{
    Spinner,
  },
  data(){
    return{      
      searchQuery:'',      
      searchResults:'',
      timer:null,
      loading:true,
    }
  },
  metaInfo: {
    title: '臭豆腐',
    titleTemplate: '%s - '
  },
  methods:{
    closeSearch(){
      this.searchQuery = '';
    },
    async search(event){      
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if(this.searchQuery.length > 0){
        if(event.key === "Enter"){
          this.fullSearch()
        }else{
        this.timer = setTimeout(async () => {
          var response = await this.axios.get(`/api/searchbar?query=${this.searchQuery}`)
          // push into array of search terms
          // watch for mouse click
          // on mouse click send a new request with the movie id & push the search into keywords
          for(let i = 0; i<response.data.results.length;i++){
            let reg = new RegExp(this.searchQuery, 'ig')
            if(response.data.results[i].nameEN) response.data.results[i].nameEN = response.data.results[i].nameEN.replace(reg,`<span class="search-highlight">${this.searchQuery}</span>`)
            if(response.data.results[i].nameCH) response.data.results[i].nameCH = response.data.results[i].nameCH.replace(reg,`<span class="search-highlight">${this.searchQuery}</span>`)
          }
          this.searchResults = response.data.results;
          this.loading = false;
        }, 600);
        }
      }
    },
    async fullSearch(){
      await this.$router.push({'path':`/search?query=${this.searchQuery}`}).catch(err=>{
        this.closeSearch();
      })
      this.closeSearch()

    },
    onkeydown(e){
      if(this.searchQuery.length > 0) this.navKeyNavigation(e)
    },
    navKeyNavigation(e){      
      if(e.key == 'Escape') this.closeSearch();      
      // if (e.key == 'ArrowDown'){}
    },
  },
  mounted() {
    document.onkeydown = this.onkeydown
     
  }
}
</script>
<style lang="scss">
.search{
  width:80%;
}
.searchbar{
  width:100%;
  z-index: 8;
  text-align: center;  
  position:relative;
  input{
    width:100%;
    padding:8px 10px;
    border-radius: 3px;
    outline:none;
    border:none;
    transition:width .3s;
  }
}
.searchIcon{
  right:0;
  top:0;
  width:25px;
  height:calc(100% - 10px);
  margin:5px 10px;
  background-image:url('../assets/img/search-icon.svg');
  background-size:20px;
  background-repeat: no-repeat;
  position:absolute;
  &.active{
    top:10px;
    right:15px;
    width:40px;
    height:40px;
    background-size:100%;
  }
}
.searchbar.active{
  position:fixed;
  z-index: 19;
  left:0;
  top:0;
  border-bottom:white solid 2px;
  background:rgba(0,0,0,.6);
  text-align:left;
  input{
    width:70%;
    margin-left:5%;
    font-size:42px;
    background:rgba(0,0,0,.1);
    color:white;
    padding:10px;
    border-radius: 3px;
    outline:none;
    border:none;
  }
}
.searchbarWrap{
  width:100vw;
  height:100vh;
  position:fixed;
  left:0;
  top:0;
  background:rgba(0,0,0,.8);
  overflow-y:auto;
  z-index: 9;
}
.searchClose{
  display:flex;
  justify-content: flex-end;
  margin-right:20px;
  #close{
    fill:white;
    padding:5px;
    border:solid white 2px;
    cursor:pointer;
    border-radius: 90%;
  }
}
.searchClose:after{
  clear:both;
}
.result{
  display:flex;
  align-items: center;
  margin:10px auto;
}
.result:hover{
  background:rgba(255,255,255,.1);
}
.searchbarResults{
  max-width:900px;
  margin:15px auto;
  padding-top:75px;
  text-align: left;
  .searchPoster{
    width:100px;
    height:112px;
    margin:0 10px;
    transition: margin .2s;
    img{
      width:75px;
      transition:width .2s;
    }
  }
  li{ 
    list-style: none;
    .titleOne{
      color:white;
      font-weight:700;
    }
    .titleTwo{
      color:grey;
    }
    .search-highlight{
      position: relative;
    }
    .search-highlight:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #5CC9F5;
      left: 0;
      bottom: -3px;
    }
    
  }
}
</style>
