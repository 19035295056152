import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Movie from '../views/Movie.vue'
// import Auth from '../views/Auth.vue'
import store from '../store/store.js'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/article/:id',
    name: 'Article',
    props:true,
    component: () => import('../views/Article.vue')
  },
  {
    path:'/admin',
    name: 'AdminHome',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsAdminHome.vue')
  },
  {
    path:'/admin/issues',
    name: 'AdminIssues',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsAdminIssues.vue')
  },
  {
    path:'/admin/movieNew',
    name: 'MovieNew',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsMovieNewEdit.vue')
  },
  {
    path:'/admin/movieEdit/:id',
    name: 'MovieEdit',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsMovieNewEdit.vue')
  },
  {
    path:'/admin/starNew',
    name: 'StarNew',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsStarNewEdit.vue')
  },
  {
    path:'/admin/starEdit/:id',
    name: 'StarEdit',
    async beforeEnter(to, from, next) {
      await store.dispatch('adminCheck')
      store.state.loggedIn == true ? next() : next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsStarNewEdit.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Forgot.vue')
  },
  {
    path: '/login',
    name: 'Login',
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      store.state.loggedIn == true ? next('/') : next()
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login/facebook/success',
    name: 'FBLogin',
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      store.state.loggedIn == true ? next('/') : next()
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/movie/:id/:activeTab(overview|tabCast|tabCrew|tabReviews|tabCritics|tabMedia|tabDetails)?',
    // component: Movie,
    name:'Movie',
    props:true,  
    component: () => import('../views/Movie.vue')
  },
  {
    path: '/genre/:model(movie|tvSeries)/:setGenre(Action|Adventure|Animation|Comedy|Crime|Documentary|Drama|Family|Fantasy|History|Horror|Music|Mystery|Romance|Science Fiction|TV Movie|Thriller|War|Western)?',
    name:'GenreList',
    props:true,
    component: () => import('../views/GenreList.vue')
  },

  {
    path: '/browse/:model(movie|tvSeries|article)/:listType(opening|inTheaters|comingSoon|latest)',
    name:'Browse',
    props:true,
    component: () => import('../views/Browse.vue')
  },
  
  {
    path: '/search',
    name: 'Search',
    props:true,
    component: () => import('../views/Search.vue')
  },
  {
    path: '/advancedsearch',
    name: 'AdvancedSearch',
    props:true,
    component: () => import('../views/AdvancedSearch.vue')
  },
  {
    path: '/star/:id',
    name: 'Star',
    props:true,
    component: () => import('../views/Star.vue')
  },
  {
    path: '/tvSeries/:id/:activeTab(overview|tabCast|tabCrew|tabReviews|tabCritics|tabMedia|tabDetails)?',
    name: 'TvSeries',
    props: true,
    component: () => import('../views/TvSeries.vue')
  },
  {
    path: '/tvSeason/:id/:season/:activeTab(overview|tabCast|tabCrew|tabReviews|tabCritics|tabMedia|tabDetails|tabSeasonEpisode)?',
    name: 'TvSeason',
    props: true,
    component: () => import('../views/TvSeries.vue')
  },
  {
    path: '/tvSeason/:id/:activeTab(overview|tabCast|tabCrew|tabReviews|tabCritics|tabMedia|tabDetails|tabSeasonEpisode)?',
    name: 'TvSeason',
    props: true,
    component: () => import('../views/TvSeries.vue')
  },
  {
    path: '/tvEpisode/:id/:activeTab(overview|tabCast|tabCrew|tabReviews|tabCritics|tabMedia|tabDetails|tabSeasonEpisode)?',
    name: 'TvEpisode',
    props: true,
    component: () => import('../views/TvSeries.vue')
  },
  
  {
    path: '/user',
    name: 'User',    
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      store.state.loggedIn == true ? next() : next('/login')
    },
    component: () => import('../views/User.vue')
  }, 
  {
    path: '/user/userReviews',
    name: 'UserReviews',    
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      store.state.loggedIn == true ? next() : next('/login')
    },
    component: () => import('../views/UserReviews.vue')
  },  
  {
    path: '/user/favorites/:type(movie|star|tvSeries)',
    name: 'UserFavorites',
    props:true,
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      store.state.loggedIn == true ? next() : next('/login')
    },
    component: () => import('../views/UserFavorites.vue')
  },  
  {
    path: '/user/articles',
    name: 'CmsArticleList',    
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      if(store.state.loggedIn != true){  next('/login')}
      if(store.state.user.adminLevel == 'editor' || store.state.user.adminLevel == 'admin') next()
      else next('/')
    },
    component: () => import('../views/cms/CmsArticleList.vue')
  },
  {
    path: '/user/article/new',
    name: 'CmsArticleNew',    
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      if(store.state.loggedIn != true){  next('/login')}
      if(store.state.user.adminLevel == 'editor' || store.state.user.adminLevel == 'admin') next()
      else next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsArticleNewEdit.vue')
  },
  {
    path: '/user/article/edit/:id',
    name: 'CmsArticleEdit',    
    async beforeEnter(to, from, next) {
      await store.dispatch('loginCheck')
      if(store.state.loggedIn != true){  next('/login')}
      if(store.state.user.adminLevel == 'editor' || store.state.user.adminLevel == 'admin') next()
      else next('/')
    },
    props:true,
    component: () => import('../views/cms/CmsArticleNewEdit.vue')
  },  
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/userdatadeletion',
    name: 'UserDataDeletion',
    component: () => import('../views/UserDataDeletion.vue')
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    props:true,
    component: () => import('../views/Profile.vue')
  },
  {
    path:'*',
    name: 'notFound',
    component: () => import('../views/NotFound.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // SCROLL BEHAVIOR WAS BUGGY
  // scrollBehavior (to, from, savedPosition) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if(savedPosition) return savedPosition
  //       else resolve({ x: 0, y: 5000 })
  //     }, 1500)
  //   })
  // }
})

export default router
