<template lang="pug">
  span
    span(v-if="type=='movie'" title="添加到我的收藏夾")
      i.favorite.pointer.far.fa-bookmark(v-if="!isFavorite" style="color:#a3c664" @click="toggleFavorite")  
      i.favorite.pointer.fas.fa-bookmark(v-if="isFavorite" style="color:#a3c664" @click="toggleFavorite")  
    span(v-if="type=='tvSeries'" title="添加到我的收藏夾")
      i.favorite.pointer.far.fa-bookmark(v-if="!isFavorite" style="color:#a3c664" @click="toggleFavorite")  
      i.favorite.pointer.fas.fa-bookmark(v-if="isFavorite" style="color:#a3c664" @click="toggleFavorite")  
    span(v-if="type=='star'" title="")
      i.favorite.pointer.far.fa-heart(v-if="!isFavorite" style="color:#a3c664" @click="toggleFavorite")  
      i.favorite.pointer.fas.fa-heart(v-if="isFavorite" style="color:#e73c7e" @click="toggleFavorite")      
</template>
<script>

import { mapState, mapActions } from "vuex";

export default {
  name: 'FavoriteButton',
  components:{
  
  },
  props:{
    id:String,
    type:String,    
    
  },
  computed:{
    ...mapState(['user']),
  },
  data(){
    return{
      isFavorite:false
    }
  },
  methods:{
    ...mapActions(['loginCheck']),
    async init(){
      if(this.user){
        let x = this.user.favorite[this.type].indexOf(this.id)
        if(x > -1){
          this.isFavorite = true;
        }else{
          this.isFavorite = false;
        }
      }
    },
    async toggleFavorite(){
      if(this.user){
        try{
          let {data} = await this.axios.post(`/api/user/favorite/${this.type}`,{id:this.id})
          if(data.err) this.flash(data.msg,'error')
          else this.flash(data.msg, 'success')
          await this.loginCheck()
          await this.init()
        }catch(err){
          this.flash(err,'error')
        }
      }else{
        this.flash('請登錄', 'error')
      }
    },
  },
  created(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
  span{
    margin-left:10px;
    font-size:24px;
  }
</style>
