<template lang="pug">
    .mtes-card-wrap
      FavoriteButton(
        :id="mtes._id"
        :type="mtes.model"
        style="position:absolute; top:-5px; right:10px;"
      )
      router-link(:to="`/${mtes.model}/${mtes._id}`") 
        .mtes-card-header(v-if="mtes.nameCH")
          h2.main-title {{mtes.nameCH}}
          h3.sub-title {{mtes.nameEN}}
        .mtes-card-header(v-else)
          h2.main-title {{mtes.nameEN}}
          h3.sub-title -
        .mtes-card-body
          .mtes-card-score
            .score-box(v-if="mtes.criticScoreAverage") {{mtes.criticScoreAverage * 20}}
            .score-box(v-else) - -
            small.score-count
              //- |0位影評
              |影評
          figure.mtes-card-poster-wrapper(v-bind:class="{loading:!imageLoadError}")
            img(v-if="!imageLoadError" v-bind:src="`${mtes.thumbnail}`" @error="imageLoadError = true")
            .no-img(v-else)
            figcaption.time-info(v-if="mtes.releaseDateTW") {{mtes.releaseDateTW | luxon('MMMM月 dd日')}}
            figcaption.time-info(v-else) {{ mtes.releaseDateUS | luxon('MMMM月 dd日')}} 
          .mtes-card-score
            .score-box(v-if="mtes.userScoreAverage") {{mtes.userScoreAverage * 20}}
            .score-box(v-else) - -
            small.score-count
              //- |0位影迷
              |影迷
</template>
<script>
import Spinner from 'vue-simple-spinner'
import FavoriteButton from '@/components/FavoriteButton'
export default {
  name: 'mtesCard',
  props: {
    mtes:Object,
    // id:String,
  },
  components:{
    Spinner,
    FavoriteButton,
  },

  data(){
    return{
      imageLoadError:false
      
    }
  },
  methods:{
    // imageLoadError () {
    //   console.log('Image failed to load');
    // }
  },
  async created(){

  }
}
</script>
<style lang="scss" scoped>
  @keyframes gradient {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
  @keyframes fadeIn {
    from {opacity: 0; margin-top:10px;}
    to {opacity: 1; margin-top:0;}
  }
  .mtes-card-wrap{
    animation:fadeIn .5s;
    animation-fill-mode: forwards;
    -webkit-backface-visibility: hidden;
    &:hover{
      box-shadow: 0 0 1px rgb(39 44 49 / 10%), 0 3.2px 16px rgb(39 44 49 / 7%);
      transform: translate3D(0, -1px, 0);
      transition: transform 0.3s ease-in, box-shadow 0.3s ease-in;
    }
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    perspective: 1000px;
    width: calc(100% - 16px);
    // min-width: calc(320px - 16px);
    flex-shrink: 0;
    margin: 0 auto 24px;
    box-shadow: 8px 6.4px 38.4px rgb(39 44 49 / 6%), 1px 3.2px 8px rgb(39 44 49 / 3%);
    transition: transform 0.5s ease-out, box-shadow 0.5s ease-out;
    
  }
  .mtes-card-header{
    text-align: center;
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    .main-title{
      font-size: 18px;
      margin-bottom: 0.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sub-title{
      font-size: 14px;
      margin-bottom: 2em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
  .mtes-card-body{
    width:100%;
    display: flex;
    justify-content: center;
    .mtes-card-score{
      // width:20%;
      display:flex;
      flex-direction: column;
      justify-content: center;
      .score-box{
        width:64px;
        height:64px;
        margin:0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #050505;
        font-weight: bold;
        border-radius: 4px;
        background-color: #e6e6e6;
      }
      .score-count {
        margin:5px 0 16px 0;
        text-align:center;
        font-size: 12px
      }
    }
    .mtes-card-poster-wrapper{
      width: 30%;
      margin-bottom:20px;
      min-width: 96px;
      max-width: 128px;

      img, .no-img{
        width: 100%;
        height:144px;
        box-shadow: 0 8px 12.8px -4.8px rgb(5 5 5 / 30%);
        margin-bottom: 12px;
        transform: translateY(-20px);
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 7s ease infinite;
      }
      .no-img{
        background-color:lightgrey;
        height:144px;
      }
      .time-info{
        font-size: 12px;
        text-align:center;
        line-height: 1;

        // &:before{
        //   content: '';
        //   display: block;
        //   width: 12px;
        //   height: 12px;
        //   // background-image: url(../../img/icons/calendar-small.png);
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   background-position: center;
        //   margin: 0 4px;
        // }
        
      }
    }
  }
  .card-review-section {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  } 
  @media screen and (max-width:1440px) {
    
  }
  @media screen and(max-width:768px) {
    .mtes-card-wrap{ 
      width:100%;
    }
  }


</style>
