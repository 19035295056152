<template lang="pug">
  footer
    //- p I am the footer  
    .footerCopy
      span Copyright © {{(new Date()).getFullYear()}} 臭豆腐電影
      router-link(to="/privacypolicy") Privacy Policy
</template>
<script>

export default {
  name: 'Footer',
  props: {
  },
  components:{
  },

  data(){
    return{

    }
  },
  methods:{
  }
}
</script>
<style lang="scss" scoped>
footer{  
  width: 100%;
  min-height: 290px;
  display:flex;
  justify-content: center;
  align-items: flex-end;
  // background: #1F2022;
  // color: white;
  padding-top: 20px;
}
</style>
