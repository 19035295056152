<template lang="pug">
//- a(v-bind:href="`/user/article/edit/${article._id}`")
.article-container
  router-link(:to="cms ? `/user/article/edit/${article._id}`: `/article/${article._id}` ")
    img(v-bind:src="article.thumbnail")
    span.typeOfArticle {{article.typeOfArticle}}
    h4
      v-clamp.userReviewContent( autoresize :max-lines="3" style="line-height:1.4em;" ) {{article.title}}
    p.publish-date <i class="far fa-clock"></i> {{article.publishDate | luxon('MMMM月 dd日 yyyy')}} - {{article.user.displayName}}
    .published(v-if="!article.published && cms") 未發表 
    .published(v-bind:class="{active:cms}" v-if="article.published && cms") 已發表

</template>

<script>
// @ is an alias to /src

import mtesCard from '@/components/mtesCard'
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import VClamp from 'vue-clamp'

export default {
  name: 'ArticleCard',
  components: {
    VClamp,
    mtesCard,
    LeftBar,
    RightBar,
  },
  props:{
    article:Object,
    cms:Boolean,

  },
  data(){
    return{

    }
  },
  metaInfo: {
    // title: 'Articles - 臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    
  },
  async created(){
    // var articles = await this.axios.get('/cms/articles')
    // this.articles = articles.data;
    // this.loading = false;
   
  }
}
</script>
<style lang="scss" scoped>
  .article-container{
    width:100%;
    overflow:hidden;
    border:solid 1px lightgrey;
    margin:1%;
    border-radius:5px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    transition: box-shadow .3s;
    position:relative;
    a{
      display:block;
    }
    &:hover{
      box-shadow: 0 6px 12px 0 rgb(0 0 0 / 30%);
    }
    
    img{
      width:100%;
    }
    h4{
      height:54px;
      padding:10px 15px 15px 15px;
      font-weight:700;
      margin-top:-15px;
      overflow-wrap: break-word;
      // transform:translateY(-15px);
      text-align:center;
    }
    .typeOfArticle{
      margin-left:10px;
      transform:translateY(-15px);
      font-size: 14px;
      padding: 7px 10px;
      display: inline-block;
      background-color: gray;
      color: white;
      font-weight: 300;
      opacity: 0.9;
      border-radius: 3px;
    }
    .publish-date{
      border-top: 1px solid #e6e6e6;
      padding:5px;
      text-align: center;
      font-size:11px;
      // transform:translateY(-15px);

    }
    .published{
      &.active{
        background-color:lightgreen;
        color:rgb(44, 62, 80);

      }
      position:absolute;
      top:10px;
      right:10px;
      padding:5px;
      background-color:red;
      color:white;
      border-radius:5px;
    }
  }
  
</style>
