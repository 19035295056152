<template lang="pug">
  .nav
    transition(name="fade")
      .overlay(v-show="overlay" @click="closeNav")
    .navTop
      .logo
        router-link(to="/")
          picture
            <source srcset="@/assets/img/beta-stinky-3.png" media="(min-width: 769px)" />
            //- <source srcset="@/assets/img/awakescore.png" media="(min-width: 200px)" />
            //- img(src="@/assets/img/tofu-logo.png")
            <source srcset="@/assets/img/stinky.png" media="(min-width: 769px)" />
            <source srcset="@/assets/img/stinky.png" media="(min-width: 200px)" />
            img(src="@/assets/img/stinky.png")
      .searchWrap
        SearchBar
      .login(v-if="!this.user" @click="loginClick")
        router-link(to="/login") 登入 或 註冊
      .user(v-else)
        .user( @click="userDropDown = true; overlay = true")
          p(v-if="user.displayName") {{user.displayName}}
          p(v-else @click="userDropDown = true;overlay = true") "A Stinky Tofu"
          img.userThumb(v-if="user.photo" @click="userDropDown = true;overlay = true" v-bind:src="user.photo")
          img.userThumb(v-else @click="userDropDown = true;overlay = true" src="@/assets/img/awakescore.png")
        transition(name="slidedown")
          .navDropDown(v-show="userDropDown")
            .arrow-up
            ul
              router-link(to="/user")
                li(@click="closeNav") 我的簡歷
              router-link(v-if="this.user.critic == false" to="/user/userReviews")
                li(@click="closeNav") 我的評論
              router-link( to="/user/favorites/movie")
                li(@click="closeNav") 保存的電影
              router-link(to="/user/favorites/star")
                li(@click="closeNav") 我最喜歡的明星
              router-link(v-if="this.user.critic == true" to="/user/criticReviews")
                li(@click="closeNav") My Critic Reviews
              //- router-link(v-if="this.user.adminLevel =='editor' || this.user.adminLevel == 'admin' " to="/user/editor")
                li(@click="closeNav") Edit Media
              router-link(v-if="this.user.adminLevel == 'editor' || this.user.adminLevel == 'admin'" to="/user/articles") 
                li(@click="closeNav") Edit Articles              
              //- router-link(to="/admin") 
                li(@click="closeNav") Admin Panel
              router-link(to="/logout") 
                li(@click="closeNav") 登出
      .mcdonnalds.pointer(@click="hamburger = !hamburger;overlay = !overlay")
        span
        span
        span
      transition(name="slidedown")
        .bigMac(v-if="hamburger")       
          div.pointer(v-if="!this.user" @click="loginClick") 
            router-link(to="/login" )
              span(@click="closeHamburger") 登入 或 註冊
          .userMobile.pointer(v-else @click="mobileUserDropDown = !mobileUserDropDown")
            img.userThumb(v-if="user.photo" v-bind:src="user.photo")
            img.userThumb(v-else src="@/assets/img/awakescore.png")
            p(v-if="user.displayName") {{user.displayName}}
            p(v-else) A Stinky Tofu
            
          transition(name="slidedown")
            //- .profileDropDown(v-if="mobileUserDropDown && this.user")
            .profileDropDown(v-if=" this.user")
              .hamburgerItems
                router-link(to="/user")        
                  .hamburgerItem(@click="closeHamburger") 
                    //- img.navIcon(src="@/assets/img/icons/user-white.svg")
                    |我的簡歷
                router-link(v-if="this.user.critic == false" to="/user/userReviews")
                  .hamburgerItem(@click="closeHamburger")
                    //- img.navIcon(src="@/assets/img/icons/comment-icon.png")
                    | 我的評論
                router-link( to="/user/favorites/movie")
                  .hamburgerItem(@click="closeHamburger") 保存的電影
                router-link(to="/user/favorites/star")
                  .hamburgerItem(@click="closeHamburger") 我最喜歡的明星
                router-link(v-if="this.user.critic == true" to="/user/criticReviews")
                  .hamburgerItem(@click="closeHamburger")
                    //- img.navIcon(src="@/assets/img/icons/star-hover.png")
                    | My Critic Reviews
                router-link(v-if="this.user.adminLevel == 'editor' || this.user.adminLevel == 'admin'" to="/user/editor") 
                  .hamburgerItem(@click="closeHamburger") 
                    //- img.navIcon(src="@/assets/img/icons/pencil.png")
                    | Edit Articles
                //- router-link(to="/user/admin") 
                  .hamburgerItem(@click="closeHamburger") 
                    //- img.navIcon.adminIcon(src="@/assets/img/icons/cube.png")
                    | Admin Panel
                router-link(to="/logout") 
                  .hamburgerItem(@click="closeHamburger") 登出
          //- div
          //-   router-link(to="/movies")
          //-     img.navIcon(src="@/assets/img/icons/ticket-icon.png")
          //-     | 電影
          //- div 
          //-   router-link(to="/tvshows")
          //-     img.navIcon(src="@/assets/img/icons/tv-icon.png")
          //-     | 電視
          //- div 
          //-   router-link(to="/stars")
          //-     img.navIcon(src="@/assets/img/icons/star-icon.png")
          //-     | 影星
          //- div 
          //-   router-link(to="/articles")
          //-     img.navIcon(src="@/assets/img/icons/pencil.png")
          //-     | 影星
    .flexBreak
    .navBot    
      
      //- div
        router-link(to="/movies")
          img.navIcon(src="@/assets/img/icons/ticket-icon.png")
          | 電影
      //- div 
        router-link(to="/tvshows")
          img.navIcon(src="@/assets/img/icons/tv-icon.png")
          | 電視
      //- div 
        router-link(to="/stars")
          img.navIcon(src="@/assets/img/icons/star-icon.png")
          | 影星
      //- div 
        router-link(to="/articles")
          img.navIcon(src="@/assets/img/icons/pencil.png")
          | 影星

              
</template>
<script>
import SearchBar from '@/components/SearchBar'
import { mapActions, mapState } from "vuex";

export default {
  name: 'NavBar',
  props: {
    msg: String
  },
  components:{
    SearchBar
  },
  computed: {
    ...mapState(['user']),
    // fullName(){
      // return `${this.user.firstName} ${this.user.lastName}`
    // }
  },
  data(){
    return{
      searchQuery:'',      
      searchResults:'',
      timer:null,
      userDropDown:false,
      hamburger:false,
      overlay:false,
      mobileUserDropDown:false,
      windowWidth:null,
    }
  },
  methods:{
    loginClick(){
      sessionStorage.setItem('loginClick', 'true');
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
        if(this.windowWidth > 768 && this.hamburger == true) {
          this.closeHamburger();
        }
    },
    closeSearch(){
      this.searchQuery = '';
    },
    closeHamburger(){
      this.mobileUserDropDown = false; 
      this.hamburger = false;
      this.overlay = false;
    },
    closeNav(){
      this.userDropDown = false;
      this.hamburger = false;
      this.overlay = false;
      this.userDropDown = false;
    },
    async search(event){
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        var response = await this.axios.get(`/api/searchbar?query=${this.searchQuery}`)
        for(let i = 0; i<response.data.results.length;i++){
          let reg = new RegExp(this.searchQuery, 'ig')
          if(response.data.results[i].nameEN) response.data.results[i].nameEN = response.data.results[i].nameEN.replace(reg,`<span class="search-highlight">${this.searchQuery}</span>`)
          if(response.data.results[i].nameCH) response.data.results[i].nameCH = response.data.results[i].nameCH.replace(reg,`<span class="search-highlight">${this.searchQuery}</span>`)
        }
        this.searchResults = response.data.results;
      }, 0);
    },
    onkeydown(e){
      if(this.searchQuery.length > 0) this.navKeyNavigation(e)
    },
    navKeyNavigation(e){
      if(e.key == 'Escape') this.closeSearch();
      // if (e.key == 'ArrowDown'){}
    },
  },
  created(){
    document.onkeydown = this.onkeydown
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      //Init
      this.getWindowWidth()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);   
  }
}
</script>
<style lang="scss" scoped>

.overlay{
  top:0;
  left:0;
  position:fixed;
  width:100vw;
  height:100vh;
  z-index: 2;
}
.nav{
  width:100%;
  padding:5px;
  // background-color:#1e2026;
  // margin-bottom:10px;
  // box-shadow: 0px 2px 10px 0px grey;
  border-bottom:1px solid rgba(204,204,204,0.5);
  
}
.navTop{
  width:100%;
  max-width:1200px;
  margin:0 auto;
  display:flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  
}
.logo{
  cursor: pointer;
  padding:0 10px 0 0;
  img{
    width:220px;
  }
}
.searchWrap{
  width:60%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.user{
  position: relative;
  display:flex;
  align-items: center;
  cursor:pointer;
  .userThumb{
    width:25px;
    margin-left:20px;
    border:solid 2px #f9fbe7;
    border-radius: 4px;
    
  }
}
.navDropDown{
  width:150px;
  color:black;
  position:absolute;
  right:0%;
  top:calc(100% + 15px);
  background-color:white;
  box-shadow: 0px 2px 10px 0px grey;
  border-radius: 5px;
  z-index: 3;
  li{
    padding:7px;
    transition: background .2s;
  }
  li:hover{
    background:rgba(0,0,0,.1);
  }
  .arrow-up {
    position:absolute;
    top:-7px;
    right:5px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-bottom: 10px solid white;
    // box-shadow: 0px 2px 10px 0px grey;

  }
}
.mcdonnalds{
  width: 50px;
  height: 40px;
  // position: absolute;
  // right: 10px;
  // top: 13px;
  line-height: 9px;
  text-align: center;
  border-radius: 5px;
  z-index: 2;
  display:none;
  span{
    width: 60%;
    display: inline-block;
    border: 2px solid rgba(204,204,204,0.9);
    border-radius: 80px;
  }
}

.adminIcon{
  filter:invert(1);
}
.bigMac{
  width:100%;
  padding:20px;
  left:0;
  top:65px;
  position:absolute;
  z-index: 10;
  // background-color:#1e2026;
  display:none;
  background: #f5f5f5;
  >div{
    padding:15px;
    border-bottom:solid 1px grey;
  }
  .profileDropDown{
    display:flex;
    // justify-content: flex-end;
    width:100%;
    .hamburgerItems{
      width:100%;
      display:flex;
      flex-direction: column;
      align-items: flex-end;
      // justify-content: flex-end;
    }
    .hamburgerItem{
      width:100%;
      padding:10px 5px;
      border-bottom:solid 1px lightgrey;
    }
  }
  .navIcon{
    width:15px;
    margin:0 7px;
  }
}
.userMobile{
    position: relative;
    display:flex;
    align-items: center;
    .userThumb{
      width:25px;
      margin-right:10px;
      border:solid 2px #f9fbe7;
      border-radius: 4px;
    }
  }
.navBot{
  width:100%;
  max-width:1200px;
  margin:10px auto;
  display:flex;
  justify-content: space-around;
  font-size:14px;
  .navIcon{
    margin:0 7px;
  }
}
@media screen and(max-width:768px) {
  .logo{    
    margin:10px;
    img{
      width:40px;
    }
  }  
  .user, .login{
    display:none;
    p{
    display:none;
    }
  }
  .mcdonnalds, .bigMac{
    display:block;
  }
  
}
</style>
