<template lang="pug">
  .wrapper.i-wrap()
    //- .section-left
      //- .section-left-tab.pointer(@click="leftTab = !leftTab; rightTab = false")
        span
        span
        span
    LeftBar(
      v-if="!loading"
      :incomingArticles="articles"
      :incomingInTheaters="comingSoon"
    )
    .section-mid(style="width:100%")
      transition(name="fade")
        .container.i-coming-soon-wrap()
          .sectionTitle
            p 臭豆腐電影看看
          Suggestion
      transition(name="fade")        
        .container.i-coming-soon-wrap(v-if="!loading")
          .sectionTitle
            p 現正熱映
          .flexBreak
          .i-coming-soon( v-for="mtes in comingSoon")
            mtesCard(:mtes="mtes")
          .flexBreak
          router-link(to="/browse/movie/inTheaters") 更多
        .container.i-config-soon-wrap(v-else style="text-align:center; justify-content:center")
          Spinner
      transition(name="fade")
        .container.i-articles-wrap(v-if="!loading")
          .sectionTitle
            p 焦點新聞          
          .flexBreak
          .article-list(v-if="articles.length > 0")
            .article-wrap(v-for="article in articles")
              ArticleCard(
                :article="article"
                :cms="false"
              )
            router-link(to="/browse/movie/inTheaters") 更多  
          .article-list(v-else)
            p 沒有文章
          

    //- .section-right(v-bind:class="{active:rightTab}")
      //- .section-left-tab.pointer(@click="rightTab = !rightTab; leftTab = false")
      //-   span
      //-   span
      //-   span
      transition(name="fade")
        RightBar(v-if="!loading")
    //- .carouselWrap(v-if="!loading")
      VueSlickCarousel(v-bind="carouselSettings")
        .homeSlideWrap(v-for="card in carouselSlides")
          SliderCard(:card="card")
    //- Suggestion
    //- section
      h4 焦點新聞


</template>

<script>
// @ is an alias to /src

import Suggestion from '@/components/Suggestion'
// import VueSlickCarousel from 'vue-slick-carousel'
// import SliderCard from '@/components/SliderCard'
import mtesCard from '@/components/mtesCard'
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import ArticleCard from '@/components/ArticleCard'
import Spinner from 'vue-simple-spinner'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Home',
  components: {
    Suggestion,
    // VueSlickCarousel,
    // SliderCard,
    Spinner,
    mtesCard,
    LeftBar,
    RightBar,
    ArticleCard
  },
  jsonld(){
    return{

    }
  },
  data(){
    return{
      loading:true,
      carouselSettings:{
        // fade:true,
        // dots:true,
        arrows:true,
        centerMode:true,
        slidesPerRow:7,
        infinite:true,
        // autoplay:true,        
        slidesToScroll: 1,
        responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesPerRow: 5,
            centerMode:true,  
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesPerRow: 3,
            centerMode:true,  
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesPerRow: 3,
            centerMode:false,  
            // dots:true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode:true,  
            arrows:false,
            slidesPerRow: 1,
            dots:true
          }
        }
      ]
      },
      leftTab:false,
      rightTab:false,
      carouselSlides:[],
      comingSoon:[],
      articles:null,

    }
  },
  metaInfo() {
    var metaStuff = [
        {property: 'og:title', content: '臭豆腐 - 電影∣影集∣評分∣評論'},
        {property: 'og:type', content: `website`},
        {property: 'og:url', content: `https://stinkytofu.tw`},
        {property: 'og:description', content: '從即時的外電資訊到專業的電影評論『臭豆腐』為你整合最全面的電影資訊！' },
        {property: 'og:image', content: "https://storage.googleapis.com/tofu-reviews/img/logo/stinky1.png"},
        {property: 'og:site_name', content: '臭豆腐 - 電影∣影集∣評分∣評論'},
        {property: 'og:locale', content: 'zh_TW'},
        {property: 'description', content: '從即時的外電資訊到專業的電影評論『臭豆腐』為你整合最全面的電影資訊！' },
      ]
    return{
      title: '臭豆腐 - 電影∣影集∣評分∣評論',
      meta:metaStuff,
      // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
    }
  },
  methods:{

  },
  async created(){
      // let newReleases = await this.axios.get('/api/newMovieReleases/0');
      // this.carouselSlides = newReleases.data;
      let comingSoon = await this.axios.get('/api/newMovieReleases/0');
      let articles = await this.axios.get('/api/articles/latest');
      this.articles = articles.data;
      this.comingSoon = comingSoon.data;
      this.loading = false;
      
  }
}
</script>
<style lang="scss" scoped>
  .i-wrap{
    display:flex;
    position:relative;
  }
  .i-suggestion-wrap{
    margin:100px 0;
  }
  .i-coming-soon-wrap{
    width:100%;
    flex-wrap:wrap;
    justify-content: center;
  }
  .i-coming-soon.active{
    height:300px;
  }
  .i-coming-soon{
    margin:10px;
    max-width:300px;
    flex-grow:0;
    // flex-basis:300px;
  }
  .i-articles-wrap{
    flex-wrap:wrap;
    justify-content: center;
  }
  .carouselWrap{
    width:calc(100% - 110px);
    // max-width:calc(1200px - 110px);
    margin:0 auto;
    margin-bottom:50px;
    .slick-prev, .slick-next{
      width:40px;
      height:40px;
      border-radius: 5px;
      background:#1e2026;
      z-index: 1;
      transition:.3s;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
      &:hover{
        box-shadow:rgba(0, 0, 0, 0.5) 0px 3px 6px 2px
      }
    }
    .slick-prev{
      left:-50px;
    }
    .slick-next{
      right:-50px;
    }
  }
  .homeSlideWrap{
    padding:10px;
  }
  .article-list{
    width:100%;
    display:flex;
    flex-wrap:wrap; 
    justify-content: center;
    .article-wrap{
      width:18%;
      margin:1%;
    }
  }
  @media screen and (max-width:1024px) {
    
    .article-list .article-wrap{
      width:23%;
      margin:1%;
    }
  }
  @media screen and (max-width:768px) {
    
    .article-list .article-wrap{
      width:30%;
      margin:1%;
    }
  }
  @media screen and (max-width:480px) {
    .article-list .article-wrap{
      width:48%;
      margin:1%;
    }
  }

</style>
