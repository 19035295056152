<template lang="pug">
  .back-to-top-wrap
    transition(name="slideup")
      .back-to-top.pointer(v-show="scY > 300" @click="toTop" )
        i.fas.fa-arrow-circle-up
        
</template>
<script>
export default {
  name: 'BackToTopButton',
  components:{
  
  },
  data(){
    return{
      scTimer: 0,
      scY: 0,
    }
  },
  methods:{
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang="scss" scoped>
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  .back-to-top-wrap{
    border-radius: 90%;
    user-select: none;
  }
  .back-to-top{
    position:fixed; 
    bottom:50px; 
    right:50px;
    background: white;
    border-radius:90%;

    &:hover{
      background: linear-gradient(-45deg,#fff2ae,#91c4fb, #ff8292,  #39f5c5 );
      // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 3s ease infinite;
    }
    i{
      font-size:42px;
    }
  }
  @media screen and (max-width:425px) {
    .back-to-top{
      bottom:75px;
      right:40px;
      i{
        font-size:48px;
      }
    }
  }
</style>
