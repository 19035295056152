<template lang="pug">
  #app   
    NavBar
    div(style="text-align:center;")
      p 
        img(src="@/assets/img/awakescore1.png" style="width:20px;margin:0 5px -5px 0;")
        | 謝謝大家測試臭豆腐電影評論BETA! 越臭越好!
        p 如果發現Bug, 請按這裡<i class="far fa-hand-point-right"></i>
          IssueButton(:nameCH="`臭豆腐電影評論`" :type="`bug`")
    router-view(:key="$route.fullPath")
    flash-message(class="flash" transitionName="fade")
    Footer
    BackToTopButton
</template>


<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import IssueButton from '@/components/IssueButton'
import BackToTopButton from '@/components/BackToTopButton'
// import '@/assets/scss/bootstrap-reboot.min.scss'

import '@/assets/scss/reset.scss'
import '@/assets/scss/main.scss'
import '@/assets/scss/fontawesome-free/css/all.min.css'
require('vue-flash-message/dist/vue-flash-message.min.css');
export default {
  name: 'App',
  components:{
    NavBar,
    IssueButton,
    Footer,
    BackToTopButton
  },
  
  metaInfo: {
    title: '臭豆腐-電影∣影集∣評分∣評論',
    titleTemplate: '%s - 臭豆腐-電影∣影集∣評分∣評論'
  },
  async created(){
    // RESETS SUGGESTION MEMORY
    sessionStorage.removeItem('suggestionMovieList');
    sessionStorage.removeItem('suggestionMovieGenre');
    // console.log(this.$cookies.keys().join("\n"));
    this.$store.dispatch('loginCheck')
    // var abc = await this.axios.get(`/api/auth/checklogin`)
    // console.log(abc)
  }
}
</script>
<style lang="scss">
body{
  background:#f5f5f5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

</style>
