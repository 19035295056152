<template lang="pug">
  .homeSlide(v-bind:title="card.nameCH ? card.nameCH : card.nameEN")
    router-link( v-bind:to="`/${card.model}/${card._id}`")
      .imgWrap
        img(v-bind:src="card.poster")
      .homeSlideTitle(v-if="card.nameCH")
        p {{card.nameCH}}
        p {{card.nameEN}}
      .homeSlideTitle(v-else)
        p {{card.nameEN}}
      .score
        |-
      
</template>


<script>

export default {
  name: 'SliderCard',
  props:{
    card:Object
  },
  components:{
  },
  data(){
    return{

    }
  },

  methods:{
   
  }
}
</script>
<style lang="scss">
  .homeSlide{
    color:black;
    background-color:#f5f5f5;
    margin:10px 0px;
    text-align: center;
    padding:10px;
    border-radius:2px;
    transition:box-shadow .3s, transform .3s;
    box-shadow:0 1px 2px 0 rgba(0,0,0,0.5);
    &:hover{
      box-shadow:rgba(0, 0, 0, 0.3) 0px 6px 12px 0px;
      transform:translateY(-5px);
    }
    .imgWrap{
      margin-bottom:20px;
      img{
        --aspect-ratio:2 / 3;
        width:300px;
        height:200px;
        max-width:100%;
        height:auto;
      }
    }
    .homeSlideTitle{
      p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:24px;
        padding:2px 0;
      }
      p:first-child{
        font-weight:700;
      }
      p:nth-child(2){
        font-size:14px;
      }
    }
  }
</style>
