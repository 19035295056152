<template lang="pug">
  span()
    i.issue-icon.fas.fa-exclamation-triangle.pointer(@click="issueModal = true" title="Is something wrong?")
    //- .issue(v-if="user && user.adminLevel !== 'user'")
    .issue()
      transition(name="fade")
        .issue-modal(v-if="issueModal")
          .issue-bg( @click="closeIssue")
          .issue-wrapper()
            div(style="text-align:right") 
              i.far.fa-window-close.pointer(@click="issueModal = false")
            h4 Submit Feedback for:
              u(v-if="nameCH") {{nameCH}}
              u(v-else) {{nameEN}}
            .input-wrap(style="text-align:left;")
              select( v-model="subject")
                option(v-for="(sub, index) in subjectList" :value="sub" ) {{subjectListCH[index]}}
            .input-wrap
              textarea-autosize(        
                placeholder="Extra Comments..."
                v-model="content"
                :min-height="70"
                :max-height="350"
              )
            div(style="text-align:right")
              button(@click="submit") Submit
     
</template>
<script>
import SliderCard from '@/components/SliderCard'
import Spinner from 'vue-simple-spinner'
import mtesCard from '@/components/mtesCard'

import { mapState } from "vuex";

export default {
  name: 'IssueButton',
  components:{
    SliderCard,
    Spinner,
    mtesCard
  },
  props:{
    id:String,
    type:String,
    nameEN:String,
    nameCH:String,
  },
  computed:{
    ...mapState(['user']),
  },
  data(){
    return{
      subjectList: ['bug', 'suggestions', 'adult','bully','idTheft','fakeAcct','duplicate','other'],
      subjectListCH: ['bug', '建議', '色情','欺凌','身份盗窃','假帳號','重複','其他'],
      subject:'bug',
      content: '',
      issueModal: false,
    }
  },
  methods:{
    async closeIssue(){
      this.issueModal = false;
    },
    async submit(){
      try{
        var payload = {subject:this.subject, id:this.id, type:this.type, content:this.content}
        var response = await this.axios.post(`/api/issue`,payload);
        if( response.data.success == true){
          this.content = '';
          this.flash('Thank you for you suggestion', 'success')
          this.issueModal = false;
        }else{
          this.flash(response.data.err, 'error')
        }
      }catch(err){
        this.flash(err, 'error')
      }
    },
    onkeydown(e){
      if(e.key == 'Escape') this.issueModal = false;
    }
  },
  created(){
    document.onkeydown = this.onkeydown
  }
}
</script>
<style lang="scss" scoped>
  .issue-icon{
    margin-left:20px;
    font-size:14px;
    vertical-align:super;
    color:rgba(0,0,0, .3);
  }
  .issue-modal{
    position:fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:99;
    background:rgba(0,0,0, .5);
    display:flex;
    justify-content: center;
    align-items: center;

  }
  .issue-bg{
    position:fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
  }
  .issue-wrapper{
    z-index: 100;
    width:50%;
    background:white;
    padding:20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .input-wrap{
    width:100%;
    margin:10px 0;
    padding:10px;
    text-align:left;
  }
  
  textarea{
    width:100%;
    padding:5px;
  }
  button{
    margin:10px 0;
  }
  @media screen and(max-width:768px) {
    .issue-wrapper{
      width:80%;
    }
    h4{
      font-size:24px;
    }
  }
</style>
