<template lang="pug">
  .container.right-wrap
    .right-section
      .right-headline
        p 焦點新聞
        p 查看全部
      .right-content

    

</template>

<script>

export default {
  name: 'RightBar',
  components: {

  },
  data(){
    return{
      loading:true,

    }
  },
  methods:{
    
  },
  async created(){

      
  }
}
</script>
<style lang="scss" scoped>
  .right-wrap{
    display:flex;
    flex-direction: column;
  }
</style>
